import { axios } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRequisitions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/requisitions', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRequisition(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/requisitions/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createRequisition(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/requisitions', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createFulfillment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/requisitions/fulfill', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    completeRequisition(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/requisitions/${params.id}/complete`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReqLineItems(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/requisitions/line_items', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}