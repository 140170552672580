import { axios } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGoodsReceiveds(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/goods_receiveds', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGoodsReceived(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/goods_receiveds/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createGoodsReceived(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/goods_receiveds', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidGoodsReceived(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/goods_receiveds/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCurrencies(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/variables/currencies")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createCurrency(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/variables', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGrcRefs(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/variables/grc_refs")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPartialLineItems(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/goods_receiveds/partial_line_items', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}